// Hosted solution

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import sha256 from "crypto-js/sha256";
import PuffLoader from "react-spinners/PuffLoader";
import "./gateway.scss";
import "react-toastify/dist/ReactToastify.css";

const publicUrlSandbox = "https://sandbox.fingenom.com";
const publicUrlProduction = "https://api.fingenom.com";
const publicUrlDev = "https://dev.fingenom.com";

const Gateway = () => {
  const month = ["Month", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const year = [
    "Year",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
  ];

  const { refno, env } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [provData, setProvData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const endpoint = {
    prod: {
      purchase: `${publicUrlProduction}/fn-execute/payment/purchase`,
      validation: `${publicUrlProduction}/fn-execute/provision/validation`,
    },
    dev: {
      purchase: `${publicUrlDev}/fn-execute/payment/purchase`,
      validation: `${publicUrlDev}/fn-execute/provision/validation`,
    },
    sandbox: {
      purchase: `${publicUrlSandbox}/fn-execute/payment/purchase`,
      validation: `${publicUrlSandbox}/fn-execute/provision/validation`,
    },
  };

  useEffect(() => {
    validateProvision();
  }, []);

  const validateProvision = async () => {
    try {
      const response = await fetch(`${endpoint[env || "sandbox"].validation}/${refno}`);
      
      
      const result = await response.json();
      if(response.status === 400 && result.message){
        toast.error(result.message);
        setLoading(false);
        setShowError(true);
        return;
      }

      setProvData(result);
      setLoading(false);
    } catch (error) {
      toast.error("Error! Please try again.");
      setShowError(true);
    }
  };

  const onSubmit = (data) => {
    if (!data) return;

    setIsSubmiting(true);

    const metaData = {
      browserUserAgent: navigator.userAgent,
      language: navigator.language,
      colorDepth: window.screen.colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      browserTZ: new Date().getTimezoneOffset(),
      javaEnabled: navigator.javaEnabled(),
      acceptHeader: "/",
    };

    const creditCard = { ...data.creditCard, number: data.creditCard.number.replace(/\s/g, "") };

    const dataObj = {
      creditCard,
      referenceNo: refno,
      browserInfo: metaData,
      provision: true,
    };

    dataObj.hash = hashBody(dataObj);
    startPayment(dataObj);
  };

  const hashBody = (body) => {
    return sha256(JSON.stringify(body)).toString();
  };

  const startPayment = async (data) => {
    try {
      if (!isChValid(data.creditCard)) {
        toast.error("Invalid card holder data");
        setIsSubmiting(false);
        return;
      }

      const response = await fetch(endpoint[env || "sandbox"].purchase, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result?.hasOwnProperty("redirect_url")) {
        window.location.href = result.redirect_url;
        return;
      }
      setShowError(true);
    } catch (error) {
      setShowError(true);
      setIsSubmiting(false);
    }
  };

  const isChValid = (data) => {
    const regexHolder = /^([a-zA-Z]+[ '-]?)*[a-zA-Z]+$/;
    const regexCvv = /^\d{3,4}$/;

    if (
      !regexHolder.test(data.holder) ||
      !regexCvv.test(data.cvv) ||
      !isCreditCardNumberValid(data.number)
    ) {
      return false;
    }

    return true;
  };

  const isCreditCardNumberValid = (cardNumber) => {
    const cardDigits = cardNumber.replace(/\s/g, "").split("").map(Number);
    cardDigits.reverse();

    let sum = 0;
    let isEven = false;

    for (let i = 0; i < cardDigits.length; i++) {
      let digit = cardDigits[i];

      if (isEven) {
        digit = digit * 2 - (digit > 4 ? 9 : 0);
      }

      sum += digit;
      isEven = !isEven;
    }

    return sum % 10 === 0;
  };

  const handleCardNumberChange = (event) => {
    const rawValue = event.target.value.replace(/\s/g, "");
    const formattedValue = rawValue
      .replace(/\D/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    setValue("creditCard.number", formattedValue);
  };

  return (
    <section className="payment-section">
      {loading ? (
        <PuffLoader color="#36d7b7" />
      ) : showError ? (
        <section className="result-section">
          <div className="result-container">
            <div className="result-content">
              <div className="success-message">
                <div className="failure">
                  <div className="failure-icon">
                    <i>×</i>
                  </div>
                  <h1>Payment failure</h1>
                  <p>Please try again later</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        provData && (
          <div className="payment-form">
            <div className="form-container">
              <div className="form-content">
                <h3>Payment Details</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <input
                      type="tel"
                      placeholder="Card Number"
                      minLength="16"
                      maxLength="23"
                      value={watch("creditCard.number")}
                      {...register("creditCard.number", {
                        required: true,
                        minLength: 16,
                        maxLength: 23,
                        onChange: handleCardNumberChange,
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Card Holder"
                      maxLength="100"
                      {...register("creditCard.holder", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      {...register("creditCard.expiryMonth", {
                        required: true,
                        validate: (value) => value !== "Month",
                      })}
                    >
                      {month.map((m) => (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      ))}
                    </select>

                    <select
                      {...register("creditCard.expiryYear", {
                        required: true,
                        validate: (value) => value !== "Year",
                      })}
                    >
                      {year.map((y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                    </select>

                    <div className="cvv">
                      <input
                        type="tel"
                        className="card-input"
                        placeholder="CVV"
                        maxLength="4"
                        minLength="3"
                        {...register("creditCard.cvv", {
                          required: true,
                          maxLength: 4,
                          minLength: 3,
                        })}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="pay-button"
                    disabled={Object.keys(errors).length > 0 || isSubmiting}
                  >
                    {isSubmiting ? (
                      <PuffLoader color="#fff" size={20} />
                    ) : (
                      `Pay ${provData?.amount} ${provData?.currency}`
                    )}
                  </button>
                </form>
              </div>
            </div>
            <div></div>
          </div>
        )
      )}
    </section>
  );
};

export default Gateway;
